.nav {
	display: block;
	width: 100%;
	border-radius: 0 0 2rem 2rem;
	position: fixed;
	top: -1000px;
	right: 0;
	background-color: rgba(black, .9);
	z-index: 99991;
	box-shadow: -5px 0 10px -5px rgba(black, .2);
	backdrop-filter: blur(5px);
	overflow: hidden;
	box-sizing: border-box;

	& .topNavMenu {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom: solid 3px $gray2;

		& p {
			margin: 0;
			font-size: 2rem;
			line-height: normal;
			font-weight: 800;
			color: $colorPrimary;
			letter-spacing: 5px;
		}
		
		& button {
			margin-right: 0;
			background-color: transparent;
			color: $colorPrimary;
			border: none;
			cursor: pointer;
			
			& i {
				font-size: 2rem;
				line-height: inherit;
				font-weight: 800;
			}
		}
	}

	& .bottomNavMenu {
		padding: 0;
		margin-top: 1rem;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		gap: 1rem;
		max-height: 50vh;
		overflow: auto;
		will-change: scroll;

		& .navItemGroup {
			width: 100%;
			margin: 0;
			display: block;

			& ul {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				width: 100%;
				margin: 0;
				
				& li {
					margin: .75rem 0;

					a {
						display: block;
						width: fit-content;

						& p {
							color: white;
							font-size: 1rem;
							font-weight: 500;
							line-height: 2rem;
							background-color: transparent;
							margin: 0;
						}
					}

					& .acaoNomeNavMenuMobile {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;

						& p {
							color: white;
							font-size: 1rem;
							font-weight: 500;
							line-height: 2rem;
							background-color: transparent;
							transition: color .1s ease-in-out;
							margin: 0;

							& i {
								color: $colorPrimary;
								font-size: .7rem;
								font-weight: 600;
								vertical-align: middle;
							}
						}
					}
				}
				
				& .subitensNavMenu {
					& a {
						& p {
							font-weight: 400;
							font-size: .9rem;
							line-height: normal;
							margin: 1rem 0;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.nav {
		border-radius: 0;
		height: 100vh;

		& .bottomNavMenu {
			max-height: 70vh;
		}
	}
}

@media (max-width: 1023px){
	.nav {
		border-radius: 0;
		height: 100vh;
		
		& .bottomNavMenu {
			flex-direction: column;
			max-height: 75vh;
			padding: 0 0 1rem 0;
			margin-top: 1rem;

			& .navItemGroup {
				& ul {
					& li {
						& .acaoNomeNavMenuMobile {
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							align-items: flex-start;
	
							& p {
								color: white;
								font-size: 1rem;
								font-weight: 500;
								line-height: 2rem;
								background-color: transparent;
								transition: color .1s ease-in-out;
								margin: 0;
	
								& i {
									color: $colorPrimary;
									font-size: .7rem;
									font-weight: 600;
									vertical-align: middle;
								}
							}
						}

						& .subitenNavMenuMobile {
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							align-items: flex-start;
							gap: .5rem;
							padding: .5rem 0 .5rem 1rem;

							& a {
								& p {
									font-size: .9rem;
								}
							}
						}
					}
				}
			}
		}
	}
}