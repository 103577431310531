.section_financiamento {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 3rem;

	& .topFinanciamento {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		gap: 1rem;

		& h1 {
			color: $colorPrimary;
			font-size: 1.75rem;
			font-weight: 600;
			margin: 0;
		}

		& p {
			margin: 0;
			font-size: 1rem;
			font-weight: 400;
			color: $gray2;
			line-height: 1.75rem;
		}
	}

	& .bancos_financiamento {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 2rem;
		width: 100%;

		& h2 {
			font-size: 1.25;
			font-weight: 600;
			color: $colorTerciary;
			margin: 0;
		}

		& .logos_bancos {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 1rem;

			& a {
				& .img {
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: 0 0 10px 1px rgba($gray2, .2);
					border-radius: 1rem;
					overflow: hidden;
					position: relative;
					top: 0;
					width: calc(150px / 1);
					height: calc(110px / 1);
					transition: all .15s ease-in-out;

					& img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					
					&:hover {
						top: -4px;
						box-shadow: 0 5px 5px 1px rgba($gray2, .5);
					}
				}

			}
		}
	}
}

@media (max-width: 1023px) {
	.section_financiamento {
		gap: 3rem;
	
		& .bancos_financiamento {
			& .logos_bancos {
				width: 100%;
				flex-wrap: wrap;
				gap: 2rem 1rem;

				& a {
					flex-basis: 47%;

					& .img {
						display: flex;
						justify-content: center;
						align-items: center;
						box-shadow: 0 0 10px 1px rgba($gray2, .2);
						border-radius: 1rem;
						overflow: hidden;
						position: relative;
						top: 0;
						width: 150px;
						height: 110px;
						transition: all .15s ease-in-out;
	
						& img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						
						&:hover {
							top: -4px;
							box-shadow: 0 5px 5px 1px rgba($gray2, .5);
						}
					}
	
				}
			}
		}
	}
}