.miniatura-imovel {
	display: inline-block;
	vertical-align: top !important;
	margin-bottom: 2rem;
	position: relative;
	width: 100%;

	& a{
		display: block;
		width: 100%;
		box-shadow: 0 0 10px 2px rgba(black, .1);
		border-radius: .5rem;

		& .miniatura-imovel-img{
			border-radius: .5rem .5rem 0 0;
			display: block;
			width: 100;
			overflow: hidden;
			padding-top: 90%;
			background-color: $gray5;
			position: relative;
			user-select: none;
			border-bottom: none;
			transition: all .2s ease-in-out;
	
			& img{
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				object-fit: fill;
			}
	
			& .miniatura_status{
				display: block;
				width: fit-content;
				position: absolute;
				top: .5rem;
				left: .5rem;
				
				& p{
					background-color: $colorPrimary;
					font-size: .75rem;
					font-weight: 600;
					text-align: center;
					color: $colorTerciary;
					padding: 0 .5rem;
					line-height: normal;
					border-radius: 2rem;
					margin: 0;
				}
			}
	
			& .miniatura-cod{
				display: block;
				width: fit-content;
				position: absolute;
				top: .5rem;
				right: .5rem;
				
				& p{
					background-color: $colorPrimary;
					font-size: .75rem;
					font-weight: 600;
					text-align: center;
					color: $colorTerciary;
					padding: 0 .5rem;
					line-height: normal;
					border-radius: 2rem;
					margin: 0;
				}
			}
		}
	
		& .miniatura-imovel-detalhe{
			display: block;
			border-radius: 0;
			width: 100%;
			padding: 1rem 1rem 0 1rem;
			background-color: white;
			@include border-box;
			position: relative;
	
			& .miniatura-titulo{
				display: block;
				width: 100%;
	
				& .miniatura_title{
					text-transform: capitalize;
					font-size: .9rem;
					font-weight: 600;
					color: $gray1;
					margin: 0;
					line-height: 1.5rem;
					height: 3rem;
				}
	
				& .miniatura_endereco{
					display: block;
					width: 100%;
					height: 2.5rem;
    				overflow: hidden;
	
					& p{
						text-transform: initial;
						font-size: .8rem;
						font-weight: 500;
						color: $gray2;
						& i{
							font-size: .7rem;
							font-weight: 400;
							color: $colorPrimary;
						}
					}
				}
			}

			& .miniatura-acao-tipo {
				width: 100%;
				
				& p{
					text-transform: initial;
					font-size: .8rem;
					font-weight: 400;
					color: $gray2;
					margin: 0;
				}
			}

			& .miniatura-imovel-btns-icons{
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: .5rem;
				height: 5rem;
				width: 100%;
	
				& ul{
					& li{
						font-size: .8rem;
						color: $gray1;
						font-weight: 400;
	
						& i{
							color: $colorPrimary;
							font-weight: 400;
							font-size: 1rem;
						}
					}
				}
			}
		}
	
		& .miniatura-preco{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100% !important;
			background-color: lighten($gray5, 3);
			border-radius: 0 0 .5rem .5rem;
			cursor: pointer;
			padding: 1rem;
			
			:first-child{
				text-align: left;
			}
	
			:last-child{
				text-align: right;
			}
	
			& p{
				margin: 0;
				font-size: 1.1rem;
				font-weight: 600;
				color: $colorTerciary;
				display: inline-block;
				vertical-align: middle;
			}
	
			& .imo_preco{
				margin: 0;
				font-size: 1.1rem;
				font-weight: 600;
				color: $colorTerciary;
				display: inline-block;
				vertical-align: middle;
			}
	
			& .miniatura_valormetragem{
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				font-size: .9rem;
				font-weight: 400;
				color: $colorTerciary;
			}
		}
	}
	
}

