.imo_title {
	display: block;
	width: 100%;

	& h1 {
		font-size: 1.5rem;
		font-weight: 500;
		color: $gray1;
	}

	& p {
		font-size: 1rem;
		font-weight: 400;
		color: $gray2;

		& i {
			color: $colorPrimary;
		}
	}
}

.info_valores {
	width: 100%;
	display: block;

	& .acao_tipo {
		font-size: .9rem;
		color: $gray2;
		font-weight: 400;
		margin: 0;
		text-align: right;
	}

	& .tarja_valor {
		font-size: 2.2rem;
		color: $colorPrimary;
		margin: 0;
		font-weight: 600;
		text-align: right;
	}

	& .iptu_cond_bloco {
		display: flex;
		flex-direction: row;
		justify-content: end;
		align-items: center;
		gap: 3rem;

		& .valor_cond {
			margin: 0 0 1rem 0;

			& .titlecond {
				font-size: .9rem;
				font-weight: 500;
				color: $gray2;
				margin: 0;
				vertical-align: middle;
				text-align: left;

				& i {
					font-size: 1.1rem;
					font-weight: 500;
					vertical-align: middle;
					color: $colorPrimary;
					cursor: pointer;
				}
			}

			& p {
				font-size: .8rem;
				font-weight: 400;
				color: lighten($gray2, 5);
				margin: .2rem 0;
				text-align: left;
			}
		}
	}
}

.container_utils_imovel {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: .5rem;

	& .item_utils_imovel {
		background-color: white;
		border-radius: 2rem;
		border: solid 1px lighten($gray4, 10);
		padding: .15rem 1rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: .5rem;
		width: fit-content;
		margin: 0;

		& p {
			color: $gray1;
			font-size: .8rem;
			font-weight: 400;
			border-radius: inherit;
			border: none;
			margin: 0;
		}

		& button {
			margin: 0 !important;
			padding: 0 !important;
			border: none;
			background-color: transparent;
			cursor: pointer;

			& i {
				display: inline-block;
				vertical-align: middle;
				font-size: 1rem !important;
				margin: 0 !important;
			}
		}
	}
}

.section_imo_interna{
	display: block;
	width: 100%;
	border-radius: .5rem;
	border: solid 1px lighten($gray4, 10);
	overflow: hidden;

	& h3{
		margin: 0;
		padding: .75rem 1rem;
		font-size: 1.1rem !important;
		font-weight: 500;
		color: $colorSecondary;
		background-color: $gray5;
	}

	& .bloco_info_imo{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 1.5rem 1rem;
		width: 100%;
		padding: 1rem;

		& p {
			font-size: .9rem;
			font-weight: 400;
			margin: 0;
			color: $gray2;
			line-height: 1.5rem;
		}
		
		& ul{
			display: block;
			width: 100%;

			& li{
				color: $gray1;
				font-size: .85rem;
				font-weight: 400;
				line-height: 1.25rem;

				& i {
					color: darken($colorPrimary, 10);
					font-size: 1rem;
				}
			}
		}
	}

	& .bloco_desc_imo {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 1rem;
		width: 100%;
		padding: 1rem;

		& p {
			font-size: .9rem;
			font-weight: 400;
			margin: 0;
			color: $gray2;
			line-height: 1.5rem;
		}
	}

	& .bloco_infras_imo {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1.5rem;
		padding: 1rem;

		& ul {
			width: fit-content;
			display: block;

			& li {
				& h4 {
					color: $gray1;
					font-size: .9rem;
					font-weight: 600;
					line-height: 1.5rem;
					margin: 0;
				}

				& p {
					color: $gray2;
					font-size: .9rem;
					font-weight: 400;
					line-height: 1.5rem;
					margin: 0;
				}
			}
		}
	}

	& .bloco_videos_imo {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1.5rem 1rem;
		padding: 1rem;
	}
}

.container_troca_promocao {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5rem;
	margin-bottom: 3rem;

	& .box_troca_promocao {
		display: block;
		width: 100%;
		height: 100%;
		border: solid 1px lighten($gray4, 10);
		border-radius: .5rem;
		overflow: hidden;

		& .title {
			font-size: 1rem;
			font-weight: 500;
			color: $gray1;
			margin: 0;
			background-color: $gray5;
			padding: .5rem 1rem;

			& i {
				color: $colorPrimary;
			}
		}

		& p {
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
			margin: 0;
			padding: 1rem;
		}
	}
}

.formulario_interno {
	display: block;
	width: 100%;
	border-radius: .5rem;
	border: solid 1px lighten($gray4, 10);
	padding: 1rem;
	position: relative;

	& h4 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $gray1;
		text-align: center;
		margin: 0;
	}

	& .btn_zap_interno {
		width: fit-content;
		display: block;
		padding: 0 2rem;
		background-color: #00af53;
		margin: auto;
		border-radius: 2rem;
		cursor: pointer;
		transition: background-color .2s ease-in-out;

		& p {
			text-align: center;
			color: white;
			font-size: 1.2rem;
			font-weight: 600;
			padding: .5rem 0;
			vertical-align: middle;
			user-select: none;

			& i {
				vertical-align: middle;
				font-size: 1.8rem;
				font-weight: 400;
			}
		}

		&:hover {
			background-color: darken(#00af53, 5);
		}
	}

	& .section_dialog2 {
		display: block;
		width: 90%;
		border: solid 2px $gray4;
		box-shadow: 0 0 10px 1px rgba(black, .1);
		padding: 1rem;
		border-radius: .5rem;
		background-color: white;
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translate(-50%, -10%);
		z-index: 1;

		& .btn_fechar_form_zap {
			display: flex;
			position: absolute;
			top: -1.8rem;
			right: -1.8rem;
			background-color: white;
			border: solid 2px #00af53;
			padding: .5rem;
			border-radius: 50%;
			width: 2.1rem;
			height: 2.1rem;
			align-items: center;
			justify-content: center;
			transition: all .2s ease-in-out;
			cursor: pointer;

			& i {
				margin: 0;
				padding: 0;
				line-height: initial;
				color: $gray3;
				transition: all .2s ease-in-out;
			}

			&:hover {
				background-color: #00af53;

				& i {
					color: white;
				}
			}
		}

		& .dialog_wpp {
			display: block;
			width: 100%;

			& h2 {
				font-size: .9rem;
				color: $gray1;
				text-align: center;
			}

			& .inputs_wpp {
				display: block;
				width: 100%;

				& .formulario-titulo {
					font-size: .9rem;
					color: $gray2;
					font-weight: 400;
					margin: 0;
					line-height: 1.2rem;
					padding-left: .5rem;
				}

				& .inputs_dados {
					display: block;
					width: 100%;

					& input {
						border: solid 1px lighten($gray4, 10);
						border-radius: 2rem;
						padding: .5rem 1rem;
						width: 100%;
					}
				}

				& .input_msg {
					display: block;
					width: 100%;

					& input {
						border: solid 1px lighten($gray4, 10);
						border-radius: 2rem;
						padding: .5rem 1rem;
						width: 100%;
					}

					& button {
						width: 100%;
						display: block;
						background-color: #00af53;
						color: white;
						font-size: .9rem;
						font-weight: 600;
						padding: .5rem 0;
						border: none;
						border-radius: 2rem;
						cursor: pointer;
						transition: all .2s ease-in-out;

						& i {
							font-weight: 400;
						}

						&:hover {
							background-color: darken(#00af53, 5);
						}
					}
				}
			}
		}
	}

	& .enviar_fechar_form_interna {
		display: flex;
		width: 100%;
		justify-content: flex-end;

		& .btn_enviar_email {
			width: 100%;
			padding: .5rem 0;
			background-color: $colorPrimary;
			color: white;
			border: none;
			border-radius: 2rem;
			font-weight: 600;
			font-size: .9rem;
			text-transform: uppercase;
			letter-spacing: .01rem;
			transition: all .2s ease-in-out;
			cursor: pointer;

			&:hover {
				background-color: darken($colorPrimary, 5);
			}
		}
	}
}

.texto_descricao_imo {
	max-width: 55rem;
	margin-left: 0;

	& p {
		color: $gray2;
		font-size: .9rem;
		font-weight: 400;
		line-height: 1.5rem;
	}
}

.dialogVideoInterna {
	width: 100%;
	height: 60vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& iframe {
		width: 100%;
		height: 100%;
		border-radius: .5rem;
	}
}

@media (min-width: 1024px) and (max-width: 1366px) {
	.imo_title {
		& h1 {
			font-size: 1.1rem;
		}

		& p {
			font-size: .9rem;
		}
	}

	.info_valores {
		& .tarja_valor {
			font-size: 1.8rem;
		}
	
		& .iptu_cond_bloco {
			gap: 2rem;
	
			& .valor_cond {
				& .titlecond {
					font-size: .85rem;
	
					& i {
						font-size: 1rem;
					}
				}
	
				& p {
					font-size: .8rem;
					margin: .2rem 0 0 0;
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1200px) {
	.section_imo_interna{
	
		& h3{
			font-size: 1rem !important;
		}
	
		& .bloco_info_imo{
			grid-template-columns: repeat(5, 1fr);
		}
	}
}

@media (max-width: 1023px) {

	.imo_title {
		& h1 {
			font-size: 1.1rem;
			color: $colorSecondary;
		}
	
		& p {
			font-size: .9rem;
		}
	}

	.info_valores_mob {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		& .acao_tipo {
			font-size: .9rem;
			font-weight: 500;
			margin: 0;
		}

		& .tarja_valor {
			font-size: 1.8rem;
			color: $colorPrimary;
			font-weight: 600;
			margin: 0 0 1rem 0;
		}

		& .valor_cond {
			width: 100%;
			display: block;
			box-sizing: border-box;
			margin-bottom: 1rem;

			& .titlecond {
				font-size: .9rem;
				font-weight: 500;
				color: $gray1;
				margin: 0;
				vertical-align: middle;

				& i {
					font-size: 1.1rem;
					font-weight: 600;
					vertical-align: middle;
					color: $colorPrimary;
					cursor: pointer;
				}
			}

			& p {
				font-size: .9rem;
				font-weight: 400;
				color: $gray2;
				margin: .2rem 0;
			}
		}
	}

	.container_utils_imovel {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1rem;

		& .item_utils_imovel {
			width: 100%;
			padding: .5rem 0;
			& p {
				font-size: 1rem;
			}
		}

		& .btn_entrar_em_contato_mobile {
			display: block;
			width: 100%;

			& button {
				width: 100%;
				padding: .5rem 0;
				border: solid 1px $colorPrimary;
				background-color: $colorPrimary;
				color: white;
				font-size: 1rem;
				font-weight: 600;
				border-radius: 2rem;

				& i {
					font-weight: 600;
				}

				&:active {
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}

	.container_troca_promocao {
		grid-template-columns: 1fr;
		gap: 1rem;
	
		& .box_troca_promocao {
			margin-bottom: 0;
		}
	}

	.section_imo_interna{
		& h3{
			font-size: 1rem !important;
		}
	
		& .bloco_info_imo{
			grid-template-columns: repeat(2, 1fr);
		}
	
		& .bloco_infras_imo {
			grid-template-columns: repeat(2, 1fr);

			& ul {
				& li {
					& h4 {
						color: $colorSecondary;
					}
				}
			}
		}
	
		& .bloco_videos_imo {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem 1rem;
			padding: 1rem;
		}
	}
}