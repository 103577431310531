/* SCROLL-BAR */
::-webkit-scrollbar{width: 5px; height: 5px;}
::-webkit-scrollbar-track-piece{background-color: $gray5;}
::-webkit-scrollbar-thumb:vertical{height: 5px; background-color: $colorPrimary;}
::-webkit-scrollbar-thumb:horizontal{width: 5px; background-color: $colorPrimary;}
::-ms-clear {width: 0; height: 0;}
::-ms-reveal {width: 0; height: 0;}

.ho-title{
	font-size: 1.3rem;
}

.ho-subtitle{
	font-size: 1.1rem;
}

.ho-txt{
	font-size: 1rem;
}

.ho-mini{
	font-size: 0.9rem;
	margin-top: 3px;
    margin-bottom: 3px;
}

.bg_section_imoveis{
	background-color: white;
	display: block;
	width: 100%;
	
	& .container_miniaturas_secao_imoveis{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1rem;
		row-gap: 1.5rem;
		align-items: flex-start;
	}
}

.bg_section_imoveis_vendas {
	background-color: $gray5;
	display: block;
	width: 100%;

	& .container_miniaturas_secao_imoveis{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1rem;
		row-gap: 1.5rem;
		align-items: flex-start;
	}
}

.btn_ver_mais{
	text-align: center;
	width: fit-content;

	& p{
		color: $colorTerciary;
		font-weight: 600;
		font-size: 1.1rem;
		transition: all .2s ease-in-out;
		&:hover{
			color: $gray2;
		}
	}
}

html{
	width: 100%;
	height: 100%;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

body{
	font-family: 'Montserrat', sans-serif;
	background-color: white;
	color: $gray2;
	font-weight: 400;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

.u-title{
	padding-bottom: 2px;
    text-decoration: none;
    border-bottom: 3px solid $colorPrimary;
}

.u-title2{
	padding-bottom: 2px;
    text-decoration: none;
    border-bottom: 3px solid white;
}

.politica_formularios{
	width: 100%;
	display: block !important;
	
	& p{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .75rem;
		user-select: none;
		& a{
			color: $colorPrimary;
			font-weight: 500;
			font-size: .75rem;
			user-select: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	& label{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .75rem;
		user-select: none;
	}

	& input{
		border-radius: 50% !important;
		width: fit-content !important;
		margin: 0 .25rem !important;
	}
}

.btn_enviar_form{
	width: fit-content;
	display: block;
	background-color: $colorPrimary;
	color: white;
	font-weight: 600;
	font-size: 1rem;
	border: solid 1px $colorPrimary;
	border-radius: 2rem;
	cursor: pointer;
	transition: all .2s ease-in-out;
	padding: .5rem 2rem;
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

h1, .h1{
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h2, .h2{
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h3, .h3{
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}


h4, .h4{
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.1em;
	color: $gray2;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}

a{
	font-size: 1rem;
	text-decoration:none;
	cursor:pointer;
	color: $gray1;

	&:hover {
		color: white;
	}
	&:visited, &:focus, &:active{
		color: $gray1;
	}
}

div{
	line-height: 1em;
	margin: auto;
}

p{
	font-weight: 400;
	color: $gray1;
	line-height: 1.4em;
	margin: 10px 0;
	font-size: 1rem;
}

.site-space-2-5 {
	display: table;
	width: 100%;
	height: 2.5rem; 
}

.containerGlobalButtons {
	z-index: 9999;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: fit-content;

	& .container_wpp_global{
		display: flex;
		gap: 1rem;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;

		& .boxBtns {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			& .btn_whatsapp_global {
				background-color: darken(#25d366, 5);
				width: 4rem !important;
				height: 4rem !important;
				border-radius: 50%;
				justify-content: center;
				align-items: center;
				display: flex;
				margin: 0;
				cursor: pointer;
				transition: background-color .2s ease-in-out;
		
				& i{
					margin: 0;
					font-size: 2rem;
					color: white;
					line-height: inherit;
				}
		
				&:hover{
					background-color: darken(#25d366, 10);
				}
			}

			& #formWppGlobal {
				background-color: white;
				box-shadow: 0px 2px 5px 2px rgba(black, .1);
				width: 330px;
				display: none;
				position: absolute;
				bottom: 0;
				left: -90%;
				transform: translateX(-90%);
				border-radius: .5rem;
				padding: 1rem;

				& form {
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;


					& .dialog_wpp {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
						gap: 1rem;

						& .titleFormWppGlobal {
							width: 100%;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;

							& p {
								margin: .5rem 0;
								width: 100%;
								max-width: 90%;
								text-align: left;
								font-size: .8rem;
								font-weight: 500;
								color: $gray1;
							}

							& .btnCloseWpp {
								background-color: $colorPrimary;
								color: white;
								border: none;
								line-height: normal;
								font-size: .8rem;
								margin: 0;
								display: flex;
								align-items: center;
								justify-content: center;
								cursor: pointer;
								border-radius: 50%;
								width: 1.5rem;
								height: 1.5rem;
								transition: all .2s ease-in-out;
			
								&:hover {
									background-color: darken($colorPrimary, 5);
								}
							}
						}

						& div {
							width: 100%;
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							align-items: flex-start;
							gap: .25rem;

							& span {
								font-weight: 500;
								font-size: .75rem;
								color: $gray2;
							}

							& input {
								border: solid 1px lighten($gray4, 10);
								width: 100%;
								padding: .5rem;
								border-radius: .2rem;
							}
						}

						& .send_wpp {
							background-color: #25d366;
							width: 100%;
							border: none;
							padding: .5rem;
							border-radius: .2rem;
							color: white;
							font-weight: 600 !important;
							transition: all .2s ease-in-out;
							cursor: pointer;

							&:hover {
								background-color: darken(#25d366, 5);
							}
						}
					}
				}

			}
		}
	
	}
}

.strong, strong, b{
	font-weight: 600 !important;
}

.danger {
	color: red !important;
}
.primary{
	color: $colorPrimary !important;
}
.secondary{
	color: $colorSecondary !important;
}
.terciary{
	color: $colorTerciary !important;
}
.red{
	color: $colorDanger !important;
}
.black{
	color: $gray1 !important;
}
.gray1{
	color: $gray1 !important;
}
.gray2{
	color: $gray2 !important;
}
.gray3{
	color: $gray3 !important;
}
.gray4{
	color: $gray4 !important;
}
.gray5{
	color: $gray5 !important;
}
.bg-c-white {
	background-color: #ffffff !important;
}
.bg-white{
	display: table;
	width: 100%;
	background-color: #ffffff;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray1{
	display: table;
	width: 100%;
	background-color: $gray1;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray2{
	display: table;
	width: 100%;
	background-color: $gray2;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray3{
	display: table;
	width: 100%;
	background-color: $gray3;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray4{
	display: table;
	width: 100%;
	background-color: $gray4;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray5{
	display: table;
	width: 100%;
	background-color: $gray5;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-primary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-primary-g{
	display: table;
	width: 100%;
}

.bg-secondary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-terciary{
	display: table;
	width: 100%;
	background-color: $colorTerciary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-danger{
	display: table;
	width: 100%;
	background-color: $colorDanger;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.box-lateral {
	margin-left: -30px;
	margin-right: -30px;
}
.box-lateral-20 {
	margin-left: -20px;
	margin-right: -20px;
}

.creccss {
	margin: 0;
	position: relative;

	& select {
		text-align: center;
		color: $gray2 !important;
		border: solid 1px lighten($gray4, 10);
		border-radius: 2rem;
		font-size: .8rem;
		font-weight: 600;
		text-transform: uppercase;
		width: 100%;
		height: auto;
		padding: .5rem 0;
		background-color: white;
		cursor: pointer;
		transition: border-color .2s ease-in-out;
		box-sizing: border-box;
		margin: 0;
		
		&:hover{
			border-color: lighten($colorPrimary, 10);
		}
	}
	
	&::after {	
		cursor: pointer;
		position: absolute;
		top: 55%;
		right: 7%;
		pointer-events:none;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		content: "\f078";
		font-size: 12px !important;
		padding: 0;
		color: $colorPrimary;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: translate(-7%, -55%);
		transform-origin: center;
		transition: transform 0.15s ease;
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin site--large(){
	html{
		font-size: 1.15vw;
	}
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin site--medium(){
	html{
		font-size: 1.3vw;
	}
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin site--tablet-landscape(){

	html{
		font-size: 1.7vw;
	}

}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin site--tablet-portrait(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin site--smart-landscape(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin site--smart-portrait(){

	html{
		font-size: 3.75vw;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin site--smart-old(){

	html{
		font-size: 3.75vw;
	}
}

.animation_in{
	display: block !important;
	animation: normal animation_in .15s ease-in-out;
}

.animation_in > .whatsapp_container{
	animation: none;
}

@keyframes animation_in{
	from{
		filter: opacity(0);
		bottom: -300px;
	}
	to {
		filter: opacity(1);
		bottom: 0;
	}
}

.animation_out{
	animation: normal animation_out .25s ease-in-out;
}

@keyframes animation_out{
	from{
		filter: opacity(1);
		bottom: 0;
	}
	to {
		filter: opacity(0);
		bottom: -300px;
	}
}

@media all and (max-width:1023px){

	.containerGlobalButtons {
		& .container_wpp_global{
			& .boxBtns {
				& #formWppGlobal {
					width: 85vw;
					bottom: 5rem;
					left: -72.5%;
					transform: translateX(-72.5%);
					border-radius: .5rem;
					padding: 1rem;
	
					& form {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
	
	
						& .dialog_wpp {
							width: 100%;
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							align-items: flex-start;
							gap: 1rem;
	
							& .titleFormWppGlobal {
								width: 100%;
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
	
								& p {
									margin: .5rem 0;
									width: 100%;
									max-width: 90%;
									text-align: left;
									font-size: .8rem;
									font-weight: 500;
									color: $gray1;
								}
	
								& .btnCloseWpp {
									width: 1.75rem;
									height: 1.75rem;
								}
							}
	
							& div {
								width: 100%;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								gap: .25rem;
	
								& span {
									font-weight: 500;
									font-size: .75rem;
									color: $gray2;
								}
	
								& input {
									border: solid 1px lighten($gray4, 10);
									width: 100%;
									padding: .5rem;
									border-radius: .2rem;
								}
							}
	
							& .send_wpp {
								background-color: #25d366;
								width: 100%;
								border: none;
								padding: .5rem;
								border-radius: .2rem;
								color: white;
								font-weight: 600 !important;
								transition: all .2s ease-in-out;
								cursor: pointer;
	
								&:hover {
									background-color: darken(#25d366, 5);
								}
							}
						}
					}
	
				}
			}
		}
	}

	.splide__pagination {
		bottom: 7.5rem !important;
	}

	.btn_ver_mais{
		& p{
			font-size: 1rem;
		}
	}

	.bg_section_imoveis{
		& .container_miniaturas_secao_imoveis{
			grid-template-columns: repeat(1, 1fr);
			grid-gap: 1rem;
			row-gap: 1.5rem;
			align-items: flex-start;
		}
	}

	.bg_section_imoveis_vendas{
		& .container_miniaturas_secao_imoveis{
			grid-template-columns: repeat(1, 1fr);
			grid-gap: 1rem;
			row-gap: 1.5rem;
			align-items: flex-start;
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px) {

	.splide__arrow--next {
	right: 2rem !important;
	}

	.splide__arrow--prev {
	left: 2rem !important;
	}

	.bg_section_imoveis{
		& .container_miniaturas_secao_imoveis{
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 1rem;
			row-gap: 1.5rem;
			align-items: flex-start;
		}
	}

	.bg_section_imoveis_vendas {
		& .container_miniaturas_secao_imoveis{
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 1rem;
			row-gap: 1.5rem;
			align-items: flex-start;
		}
	}
}