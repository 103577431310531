.section_depoimentos{
    background-color: white;
    display: block;
    width: 100%;

    & .container_depoimentos{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        align-items: flex-start;

        & .miniatura_depoimento{
            display: block;
            width: 100%;
            border: solid 1px lighten($gray4, 10);
            border-radius: .5rem;
            padding: 1rem;
            box-sizing: border-box;
            margin: 0;
        
            & .imagem_nome{
                display: flex;
                justify-content: flex-start;
                align-items: center;
        
                & img{
                    width: 15%;
                    margin-right: 1rem;
                    border-radius: 50%;
                }
        
                & .nome{
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: $colorTerciary;
                    line-height: 1.2rem;
                    margin: 0;
                    
                    & .cargo{
                        font-size: .85rem;
                        font-weight: 400;
                        color: $gray2;
                        margin: 0;
                    }
                }
            }
        
            & .depoimento{
                font-size: .9rem;
                font-weight: 400;
                color: $gray2;
                min-height: 3.7rem;
                max-height: 3.7rem;
                overflow: hidden;
            }
        
            & .vermais_btn{
                display: block;
                width: fit-content;
                margin-right: 0;
                
                & .vermais{
                    font-size: .85rem;
                    font-weight: 600;
                    color: white;
                    background-color: $colorPrimary;
                    user-select: none;
                    cursor: pointer;
                    text-align: right;
                    transition: all .2s ease-in-out;
                    padding: .3rem 1.5rem;
                    border-radius: 2rem;
                    margin: 0;
                    line-height: inherit;
                    
                    &:hover{
                        background-color: darken($colorPrimary, 5);
                    }
                }
            }
        
            & .data{
                font-size: .8rem;
                font-weight: 400;
                color: $gray2;
                margin: 0;
            }
        }
    
        & .nada_encontrado{
            display: block;
            width: 100%;
    
            & h2{
                text-align: center;
                color: $gray1;
                font-size: 1.5rem;
                font-weight: 600;
            }
    
            & a{
                display: block;
                width: fit-content;
                margin: auto;
                & p{
                    background-color: $colorPrimary;
                    font-size: 1rem;
                    font-weight: 600;
                    color: white;
                    padding: .2rem 1rem;
                    border-radius: .1rem;
                    transition: all .2s ease-in-out;
                    &:hover{
                        background-color: darken($colorPrimary, 5);
                    }
                }
            }
        }
    
        & button{
            border-radius: .2rem;
        }
    }
}

@media all and (max-width: 1023px){
    .section_depoimentos{
        & .container_depoimentos{
            grid-template-columns: repeat(1, 1fr);
        }
    }
}