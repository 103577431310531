.section_servicos {
	width: 100%;
	display: block;
	background-color: $gray5;

	& .containerServicos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;
		gap: 1rem;

		& .miniaturaServicos {
			flex: 1;
			background-color: white;
			box-sizing: border-box;
			border-radius: 1rem;
			box-shadow: 0 0 10px 1px rgba($gray2, .1);
			position: relative;
			top: 0;
			margin: 0;
			transition: all .15s ease-in-out;

			& a {
				height: 100%;
				display: block;

				& .conteudoMiniatura {
					padding: 1rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 1.5rem;

					& .img {
						width: calc(512px / 9);
						height: calc(512px / 9);
						margin: 0;
						display: flex;
						justify-content: center;
						align-items: center;

						& img {
							width: calc(512px / 9);
							height: calc(512px / 9);
						}
					}

					& .miniaturaServicosText {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						gap: .5rem;

						& h3 {
							font-size: .9rem;
							font-weight: 600;
							margin: 0;
							text-align: center;
							color: $gray1;
						}

						& p {
							color: $gray2;
							font-size: .8rem;
							font-weight: 400;
							margin: 0;
							text-align: center;
							line-height: 1.5rem;
						}
					}
				}
			}

			&:hover {
				top: -4px;
				box-shadow: 0 5px 10px 1px rgba($gray2, .5);
			}
		}
	}
}

@media (max-width: 1023px) {

	.section_servicos {
		& .containerServicos {
			display: flex;
			flex-wrap: inherit;
			flex-direction: column;
			gap: 2rem;
		}
	}
}