@mixin font-size{

	/* Exemplo <p class="font24"></p> */
	$i: 8;
	$valor: 8px;

	@while $i <= 50 {

		.font#{$i}{ 
			font-size: $valor !important; 
		}

		$valor: $valor + 1;
		$i: $i + 1;

	}
}

@mixin font-size-rem{

	/* Exemplo <p class="font24"></p> */
	$i: 1;
	$valor: .25rem;

	@while $i <= 30 {
		.fontr#{$i}{ 
			font-size: $valor !important; 
		}

		$valor: $valor + .25;
		$i: $i + 1;

	}
}

@mixin max{

	/* Exemplo <div class="maxwidth320"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 100 {

		.max#{$i} { 
			max-width: $valor !important;
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}
}

@mixin width{

	/* Exemplo <div class="width10"></div> */

	/* REM */
	$i: 1;
	$valor: 1rem;
	@while $i <= 30 {

		.width-rem-#{$i}{ 
			width: $valor !important;
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}


	/* PORCENTAGEM */
	$i: 1;
	$valor: 1%;
	@while $i <= 100 {

		.width-#{$i}{ 
			width: $valor; 
		}

		$valor: $valor + 1%;
		$i: $i + 1;
	}

	.width-12-5{
		width: 12.5%;
	}

	.width-66-6{
		width: 66.66%;
	}

	.width-16-6{
		width: 16.66%;
	}

	.width-33{
		width: 33%;
	}

	.width-33-3{
		width: 33.33%;
	}
}

@mixin height{

	/* Exemplo <div class="height10"></div> */

	/* REM */
	$i: 1;
	$valor: 1rem;
	@while $i <= 30 {

		.height-rem-#{$i}{ 
			height: $valor; 
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}


	/* PORCENTAGEM */
	$i: 1;
	$valor: 1%;
	@while $i <= 100 {

		.height-#{$i}{ 
			height: $valor; 
		}

		$valor: $valor + 1%;
		$i: $i + 1;
	}

	.height-12-5{
		height: 12.5%;
	}

	.height-66-6{
		height: 66.66%;
	}

	.height-16-6{
		height: 16.66%;
	}

	.height-33{
		height: 33%;
	}

	.height-33-3{
		height: 33.33%;
	}
}
	
@mixin site-space{

	/* Exemplo <div class="site-space-20"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 50 {

		.site-space-#{$i}{
			display: table;
			width: 100%;
			height: $valor;
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}

	.site-space-5{
		display: table;
		width: 100%;
		height: 5rem;
	}

	.site-space{
		display: table;
		width: 100%;
		height: .2rem;
	}
}

@mixin pd-left{

	/* Exemplo <div class="padding-left-30"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 80 {

		.pd-left-#{$i}{ 
			padding-left: $valor !important; 
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}

	.pd-left{
		padding-left: 1rem; 
	}
}

@mixin pd-all{

	/* Exemplo <div class="padding-left-30"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 80 {

		.pd-#{$i}{ 
			padding: $valor !important; 
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}

	.pd{
		padding: 1rem; 
	}
}

@mixin pd-top{

	/* Exemplo <div class="padding-top-30"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 80 {

		.pd-top-#{$i}{ 
			padding-top: $valor !important;
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}

	.pd-top{
		padding-top: 0.5rem; 
	}
}

@mixin pd-bottom{

	/* Exemplo <div class="padding-bottom-30"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 80 {

		.pd-bottom-#{$i}{ 
			padding-bottom: $valor !important; 
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}

	.pd-bottom{
		padding-bottom: .5rem; 
	}
}

@mixin pd-right{

	/* Exemplo <div class="padding-right-15"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 80 {

		.pd-right-#{$i}{ 
			padding-right: $valor !important;
		}


		$valor: $valor + 1rem;
		$i: $i + 1;
	}

	.pd-right{
		padding-right: 1rem; 
	}
}

@mixin pd{

	/* Exemplo <div class="pd-center-15"></div> */

	$i: 1;
	$valor: 1rem;

	@while $i <= 60 {

		.pd-center-#{$i}{ 
			padding: 0 $valor; 
		}

		$valor: $valor + 1rem;
		$i: $i + 1;
	}

	.pd-center{
		padding-left: .2rem; 
		padding-right: .2rem; 
	}
	.pd-center-min{
		padding-left: .5rem; 
		padding-right: .5rem; 
	}
	.pd-left-min{
		padding-left: .5rem; 
	}
	.pd-right-min{
		padding-right: .5rem; 
	}
}

/* OTIMIZAÇÃO */
@mixin rotating{
	.rotating{
		@include animation(rotating 1s cubic-bezier(0.24, 0.49, 0.71, 0.54) infinite);
	}
	@include keyframes(rotating){
		from{
			@include rotate(0deg);
		}
		to{
			@include rotate(360deg);
		}
	}
}

@mixin placeholder($color){

	/* PLACEHOLDERS */
	input::placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	input::-webkit-input-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	input::-moz-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	input:-ms-input-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	input:-o-input-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	textarea::placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	textarea::-webkit-input-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	textarea::-moz-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	textarea:-ms-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
	textarea:-o-placeholder{
		color: #{$color} !important;
		font-weight: normal !important;
	}
}

@mixin keyframes($nomeAnimacao){
    @-webkit-keyframes #{$nomeAnimacao} {
        @content;
    }
    @-moz-keyframes #{$nomeAnimacao} {
        @content;
    }
    @-o-keyframes #{$nomeAnimacao} {
        @content;
    }
    @keyframes #{$nomeAnimacao} {
        @content;
    }
}

@mixin animation-direction($value){
	-moz-animation-direction: #{$value};
	-webkit-animation-direction: #{$value};
	animation-direction: #{$value};
}
@mixin animation-iteration-count($value){
	-moz-animation-iteration-count: #{$value};
	-webkit-animation-iteration-count: #{$value};
	animation-iteration-count: #{$value};
}
@mixin animation-name($value){
	-moz-animation-name: #{$value};
	-webkit-animation-name: #{$value};
	animation-name: #{$value};
}
@mixin animation-duration($value){
	-moz-animation-duration: #{$value};
	-webkit-animation-duration: #{$value};
	animation-duration: #{$value};
}
@mixin filter($name){
	-webkit-filter: #{$name};
	-moz-filter: #{$name};
	-o-filter: #{$name};
	-ms-filter: #{$name};
	filter: #{$name};
}
@mixin animation($name){
	-webkit-animation: #{$name};
	-moz-animation: #{$name};
	-o-animation: #{$name};
	-ms-animation: #{$name};
	animation: #{$name};
}
@mixin transition($name){
	-webkit-transition: #{$name};
	-moz-transition: #{$name};
	-o-transition: #{$name};
	-ms-transition: #{$name};
	transition: #{$name};
}
@mixin translate3d($name){
    transform: translate3d(#{$name});
    -o-transform: translate3d(#{$name});
    -ms-transform: translate3d(#{$name});
    -moz-transform: translate3d(#{$name});
    -webkit-transform: translate3d(#{$name});
}
@mixin border-radius($valor){
	border-radius: #{$valor};
	-moz-border-radius: #{$valor};
	-ms-border-radius: #{$valor};
	-webkit-border-radius: #{$valor};
	-o-border-radius: #{$valor};
}
@mixin appearance($valor){
	appearance: $valor;
	-webkit-appearance: $valor;
	-moz-appearance: $valor;
	-o-appearance: $valor;
	-ms-appearance: $valor;
}
@mixin user-select($valor){
	user-select: $valor;
	-webkit-user-select: $valor;
	-moz-user-select: $valor;
	-ms-user-select: $valor;
	-o-user-select: $valor;
}
@mixin transform($value){
	-webkit-transform: #{$value};
		-o-transform: #{$value};
			transform: #{$value};
}
@mixin rotate($deg){
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-ms-transform: rotate($deg);
	-o-transform: rotate($deg);
	transform: rotate($deg);
}
@mixin scale($scale){
	-webkit-transform: scale($scale);
	-moz-transform: scale($scale);
	-ms-transform: scale($scale);
	-o-transform: scale($scale);
	transform: scale($scale);
}
@mixin opacity($valor){
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$valor)";
	filter: alpha(opacity=$valor);
	opacity: $valor;
}
@mixin box-shadow($valor){
	-webkit-box-shadow: #{$valor};
	-moz-box-shadow: #{$valor};
	-o-box-shadow: #{$valor};
	box-shadow: #{$valor};
}
@mixin highlight-color-rgba($a, $b, $c, $d){
	-webkit-tap-highlight-color: rgba($a, $b, $c, $d);
	-ms-tap-highlight-color: rgba($a, $b, $c, $d);
	-moz-tap-highlight-color: rgba($a, $b, $c, $d);
	-o-tap-highlight-color: rgba($a, $b, $c, $d);
}
@mixin border-box{
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
@mixin overflow-scrolling($valor){
	overflow-scrolling: $valor;
	-webkit-overflow-scrolling: $valor;
	-moz-overflow-scrolling: $valor;
	-o-overflow-scrolling: $valor;
	-ms-overflow-scrolling: $valor;
}
@mixin font-smooth($valor){
	-webkit-font-smoothing: $valor;
	-moz-font-smoothing: $valor;
	-o-font-smoothing: $valor;
	-ms-font-smoothing: $valor;
	font-smoothing: $valor;
}
@mixin hide-yellow-bg-input{
	input:-webkit-autofill,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	select:-webkit-autofill,
	select:-webkit-autofill:focus,
	select:-webkit-autofill:active
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:focus,
	textarea:-webkit-autofill:active{
		background-color: rgba(0,0,0,0) !important;
		transition: background-color 5000s ease-in-out 0s;
	}
}
@mixin detalhe($cor, $largura){
	display: block;
	content: "";
	position: relative;
	margin: 3px 0 20px 0;
	left: 0;
	background-color: $cor;
	width: $largura;
	height: 2px;
}
@mixin mobile-text-center{
	.mobile-text-center{
		text-align: center !important;
	}
	.mobile-text-left{
		text-align: left !important;
	}
	.mobile-text-right{
		text-align: center !important;
	}
}
@mixin selection($cor, $valor) {
	::-moz-selection {
		background: lighten($cor, $valor);
	}
	::selection {
		background: lighten($cor, $valor);
	}
}