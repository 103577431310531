$velNav: 0.25s;
.nav_open {
	@include animation-duration($velNav);
	@include animation-name(nav_open);
	@include animation-iteration-count(1);
	@include animation-direction(normal);

	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
	will-change: top;

}
@include keyframes('nav_open'){
	from {
		top: -1000px;
		right: 0;
	}
	to {
		top: 0;
		right: 0;
	}
}

.nav_close {
	@include animation-duration($velNav);
	@include animation-name(nav_close);
	@include animation-iteration-count(1);
	@include animation-direction(normal);
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
	will-change: top;

}
@include keyframes('nav_close'){
	from {
		top: 0;
		right: 0;
	}
	to {
		top: -1000px;
		right: 0;
		will-change: auto;
	}
}