.footer1{
	border-top: solid 3px $colorPrimary;
	width: 100%;
	display: block;
	background-color: white;

	& .conteudoFooter {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		& .footerLeft {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1rem;
			margin: 0;
	
			& .logo_footer{
				display: block;
				width: 100%;
				margin-bottom: 2rem;
		
				& a{
					display: block;
					width: fit-content;
		
					& .img{
						display: block;
						width: 200px;
						height: 200px;
						margin: 0;
						
						& img{
							width: 200px;
							height: 200px;
						}
					}
				}
			}
		
			& .infoFooter {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 1.5rem;
				margin: 0;
	
				& .redesSociaisFooter {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					gap: 1.5rem;
					margin: 0;
	
					& a {
						& p {
							margin: 0;
							& i {
								color: $colorPrimary;
								font-size: 1.25rem;
							}
						}
					}
				}
	
				& .creciFooter {
					font-size: .8rem;
					font-weight: 500;
					margin: 0;
					color: $gray2;
				}
	
				& .contatosFooter {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					margin: 0;
					gap: 1.5rem;
	
					& div {
						margin: 0;
						width: fit-content;
						
						& a {
							& p {
								margin: 0;
								font-size: .8rem;
								font-weight: 400;
								text-align: left;
	
								& i {
									color: $colorPrimary;
									font-size: 1.1rem;
									vertical-align: middle;
								}
							}
						}
					}
				}
	
				& .enderecoFooter {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					gap: .5rem;
	
					& i {
						color: $colorPrimary;
						font-size: 1.1rem;
					}
	
					& p {
						margin: 0;
						font-size: .8rem;
						font-weight: 400;
						color: $gray2;
						line-height: 1.25rem;
					}
				}
	
				& .horarioatendimentoFooter {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					gap: .5rem;
	
					& i {
						color: $colorPrimary;
						font-size: 1.1rem;
					}
	
					& div {
						margin: 0;
	
						& p {
							margin: 0;
							font-size: .8rem;
							font-weight: 400;
							color: $gray2;
							line-height: 1.25rem;
						}
					}
				}
			}
		}

		& .footerRight {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: 50%;
			gap: 2rem;
			margin: 0;

			& .menuFooter {
				margin: 0;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-direction: row;
				width: 100%;

				& .menuFooterLeft {
					margin: 0;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					width: 100%;
					gap: 1rem;

					& a {
						& p {
							margin: 0;
							font-size: .9rem;
							font-weight: 400;
						}
					}
				}

				& .menuFooterRight {
					margin: 0;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					width: 100%;
					gap: 1rem;

					& a {
						& p {
							margin: 0;
							font-size: .9rem;
							font-weight: 400;
						}
					}
				}
			}

			& .mapFooterRight {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin: 0;
				width: 100%;

				& iframe {
					margin: 0;
					width: 100%;
					height: 350px;
				}
			}
		}
	}

	& .bottomFooter {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 0;
		background-color: $gray1;

		& .containerBottomFooter {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin: 0;
			padding: .75rem 0;

			& p {
				margin: 0;
				color: $gray5;
				font-size: .85rem;
				font-weight: 500;

				& a {
					color: $gray5;
					font-size: .85rem;
					font-weight: 600;
					transition: color .15s ease-in-out;

					&:hover {
						color: $colorPrimary;
					}
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.footer1{
		& .conteudoFooter {
			flex-direction: column;
			gap: 4rem;
	
			& .footerLeft {
				& .logo_footer{
					margin-bottom: 0;
					& a{
						margin: auto;
						& .img{
							width: 175px;
							height: 175px;
							
							& img{
								width: 175px;
								height: 175px;
							}
						}
					}
				}
			
				& .infoFooter {
					& .redesSociaisFooter {
						justify-content: center;
						width: 100%;
						gap: 2.5rem;
						margin-bottom: 1rem;

						& a {
							& p {
								& i {
									font-size: 1.75rem;
								}
							}
						}
					}
		
					& .creciFooter {
						margin: 0 auto;
					}
		
					& .contatosFooter {
						& div {
							& a {
								& p {
									font-size: .85rem;

									& i {
										font-size: 1.3rem;
									}
								}
							}
						}
					}
		
					& .enderecoFooter {
						& i {
							font-size: 1.3rem;
						}
		
						& p {
							font-size: .85rem;
							line-height: 1.3rem;
						}
					}
		
					& .horarioatendimentoFooter {
						& i {
							font-size: 1.3rem;
						}
		
						& div {
							& p {
								font-size: .85rem;
								line-height: 1.5rem;
							}
						}
					}
				}
			}
	
			& .footerRight {
				width: 100%;

				& .menuFooter {
					gap: 1.5rem;
					flex-direction: column;
	
					& .menuFooterLeft {
						gap: 1.5rem;
					}
	
					& .menuFooterRight {
						gap: 1.5rem;
					}
				}
			}
		}

		& .bottomFooter {
			& .containerBottomFooter {
				flex-direction: column;
				padding: 1rem 0;
				gap: 2rem;
	
				& p {
					text-align: center;
				}
			}
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}