.title_condomi{
	text-align: center;
	display: block;
	width: 100%;
	
	& h2{
		font-size: 2rem;
		font-weight: 600;
		color: $colorTerciary;
		margin: 0;

		& span{
			font-size: 1.3rem;
			font-weight: 400;
			color: $gray2;
		}
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 3px;
		margin: auto;
		background-color: $colorPrimary;
		border-radius: 2rem;
	}
}

.title_condomi2{
	text-align: center;
	
	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: $colorTerciary;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
		border-radius: 2rem;
	}
}

.bg_busca_interna{
	background-color: white;
	display: block;
	width: 100%;

	& h2{
		text-align: center;
		color: $colorTerciary;
		font-size: 1.8rem;
		font-weight: 600;
	}

	& .container_filtros_busca_interna{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 2rem 1rem;

		& .grid_items{
			width: 100%;

			& .titulo-formulario{
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: .5rem;
			}
		}
	}
}

#titulo_busca{
	font-size: 1.25rem;
}

.botao_voltar {
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	border-radius: 2rem;
	padding: .3rem 1rem;
	color: $gray1;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	& i {
		color: $colorPrimary;
		font-weight: 600;
		font-size: .8rem;
	}

	&:hover{
		border-color: lighten($colorPrimary, 10);
	}
}

.btn_enviar_email{
	width: 100%;
	padding: .5rem 0;
	background-color: $colorPrimary;
	color: white;
	border: none;
	border-radius: 2rem;
	font-weight: 600;
	font-size: .8rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	transition: all .2s ease-in-out;
	cursor: pointer;

	&:hover{
		background-color: darken($colorPrimary, 10);
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorTerciary;
	vertical-align: middle;
	& i{
		color: $colorPrimary;
	}
}

.containerDocumentacaoContato {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 1rem;
	margin-bottom: 4rem;

	& h3 {
		color: $colorPrimary;
		font-size: 1.5rem;
		font-weight: 600;
		margin: 0;
	}

	& h4 {
		color: $gray1;
		font-size: 1.1rem;
		font-weight: 600;
		margin-bottom: 0;
	}

	& p {
		margin: 0;
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;
	}
}

#render-busca{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 1rem;
	row-gap: 1.5rem;
	align-items: flex-start;
}

.containerVideos {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 2rem;
	width: 100%;
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.containerVideos {
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
		width: 100%;
	}
}

@media (max-width: 1023px){

	.title_condomi{
		& h2{
			font-size: 1.4rem;
	
			& span{
				font-size: 1rem;
			}
		}
	
		& .under_p{
			height: 3px;
		}
	}

	.title_condomi2{
		& h2{
			font-size: 1.4rem;
		}
	
		& .under_p{
			height: 3px;
		}
	}

	.bg_busca_interna{
		& h2{
			font-size: 1.75rem;
		}
	
		& .container_filtros_busca_interna{
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}

	.containerDocumentacaoContato {
		margin-bottom: 4rem;
	
		& h3 {
			font-size: 1.3rem;
		}
	
		& h4 {
			font-size: 1rem;
		}
	
		& p {
			font-size: .9rem;
		}
	}

	#render-busca{
		grid-template-columns: repeat(1, 1fr);
		column-gap: 1rem;
		row-gap: 1.5rem;
		align-items: flex-start;
	}

	.containerVideos {
		grid-template-columns: 1fr;
	}
}
