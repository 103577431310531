.formsimple-selectmultiple{
	&::after {	
		cursor: pointer;
		position: absolute;
		top: -9px;
		right: 15px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 12px !important;
		padding: .7rem 0;
		color: $colorPrimary;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: white;
		color: $gray2 !important;
		font-size: .9rem !important;
		font-weight: 400 !important;
		border: solid 1px lighten($gray4, 10);
		border-radius: 2rem;
		padding:  0 1rem;
		text-align: left;
		transition: all .2s;
		&:hover{
			background-color: $gray5;
		}
	}

	& .component {
		border: 1px solid lighten($gray4, 10) !important;
		z-index: 10;
		border-radius: 1rem;
		z-index: 9999;

		& .items{
			& .group{
				color: $gray2 !important;
				font-size: .9rem;
			}
		}


		& .botoes {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 1rem;
			

			& .undo{
				margin: 0 !important;
				width: 100%;
				background-color: transparent;
				color: $colorTerciary !important;
				font-weight: 600;
				padding: 0.5rem 0;
				border-radius: 2rem;
				cursor: pointer;
				text-transform: uppercase;
				font-size: 0.65rem;
				transition: all .2s ease-in-out;
				&:hover{
					background-color: $gray5;
				}
			}

			& .ok{
				margin: 0 !important;
				width: 100%;
				background-color: $colorPrimary;
				color: white !important;
				font-weight: 600;
				padding: 0.5rem 0;
				border-radius: 2rem;
				cursor: pointer;
				text-transform: uppercase;
				font-size: 0.65rem;
				transition: all .2s ease-in-out;
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}

			& button {
				display: block;
				margin: 0;
				outline: none;
				border: 0;
				background-color: transparent;
				height: inherit;
			}
		}
	}

}
