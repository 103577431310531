.imoveis_semelhantes{
	display: block;
	width: 100%;
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	padding: 1rem;
	border-radius: .5rem;

	& h4{
		font-size: 1rem;
		text-align: left;
		color: $colorTerciary;
		font-weight: 600;
		margin: 0;
	}

	& .miniatura_interna{
		background-color: white;
		width: 100%;
		display: block;
		padding: .7rem;
		border: solid 1px lighten($gray4, 10);
		border-bottom: none;
		position: relative;
		border-radius: .5rem .5rem 0 0;

		& a{
			display: block;
			width: 100%;
	
			& .bloco_img{
				width: 40%;
				display: inline-block;
				vertical-align: top;
				margin: 0;
	
				& .miniatura_imovel_img{
					display: block;
					width: 100%;
					background-color: $gray5;
					position: relative;
					user-select: none;
					border-radius: .5rem;
					position: relative;
					padding-top: 80%;
					overflow: hidden;					

					& img{
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						object-fit: fill;
					}
				}
			}
		   
			& .bloco_informacoes{
				width: 60%;
				display: inline-block;
				vertical-align: top;
				margin: 0;
				padding-left: 1rem;

				& .miniatura_informacoes{
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					width: 100%;
					margin: 0;
					gap: .1rem;

					& .miniatura_titulo{
						display: block;
						width: 100%;
				
						& p{
							font-size: .85rem;
							font-weight: 500;
							color: $gray1;
							margin: 0;
						}
					}
						
					& .acao_tipo_interna{
						width: 100%;
						display: block;

						& p{
							font-size: .8rem;
							font-weight: 400;
							color: $gray1;
						}
															
						& .miniatura_acao{
							font-size: .8rem;
							font-weight: 400;
							margin: 0;
							color: $gray2;
							background-color: transparent;
						}
					}
	
					& .miniatura_endereco{
						display: block;
						width: 100%;

						& p{
							font-size: .8rem;
							font-weight: 400;
							color: $gray2;
							margin: 0;

							& i{
								font-size: .7rem;
								font-weight: 600;
								color: $colorPrimary;
							}
						}
					}

					& .miniatura_preco{
						display: block;
						width: 100%;

						& p{
							width: 100%;
							text-align: left;
							font-size: .9rem;
							font-weight: 500;
							color: $colorTerciary;
							line-height: none;
							margin: 0;
						}

						& .tt{
							font-size: .75rem;
							color: $gray2;
							font-weight: 400;
							margin: .5rem 0 0 0;
						}
					}
				}
			}
		}
	}

	& .miniatura-imovel-btns-iconsX{
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		background-color: lighten($gray5, 1);
		border: solid 1px lighten($gray4, 10);
		border-top: none;
		padding: .5rem;
		border-radius: 0 0 .5rem .5rem;

		& ul{
			& li{
				color: $gray1;
				font-size: .8rem;
				font-weight: 400;

				& i{
					font-weight: 400;
					font-size: .9rem;
					color: $colorPrimary;
				}
			}
		}
	}
	
	& .btn_vermais_interna{
		display: block;
		width: fit-content;
		margin: 0;
		& a{
			& p{
				font-size: .8rem;
				font-weight: 600;
				color: $gray1;
				background-color: white;
				border-radius: 2rem;
				padding: .3rem 1rem;
				text-align: center;
				transition: all .2s ease-in-out;
				border: solid 1px $gray4;
				margin: 0;

				& i {
					color: $colorPrimary;
				}

				&:hover{
					border-color: lighten($colorPrimary, 10);
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.imoveis_semelhantes{
		& .miniatura_interna{
			& a{
				& .bloco_img{
					& .miniatura_imovel_img{
						padding-top: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.imoveis_semelhantes{
		& .miniatura_interna{
			background-color: white;
			width: 100%;
			display: block;
			padding: .7rem;
			border: solid 1px lighten($gray4, 10);
			position: relative;
			border-radius: 1rem;
	
			& a{
				display: block;
				width: 100%;
		
				& .bloco_img{
					width: 100%;
					display: block;
					margin: 0;
		
					& .miniatura_imovel_img{
						display: block;
						width: 100%;
						background-color: $gray5;
						user-select: none;
						border-radius: .5rem;
				
						& img{
							display: block;
							width: 100%;
							border-radius: .5rem;
						}
					}
	
					& .miniatura_preco{
						display: block;
						width: 100%;
	
						& .tt{
							font-size: .75rem;
							color: $gray2;
							font-weight: 400;
							margin: .5rem 0 0 0;
						}
	
						& p{
							width: 100%;
							text-align: left;
							font-size: 1rem;
							font-weight: 500;
							color: $gray1;
							line-height: none;
							margin: 0;
						}
					}
				}
			   
				& .bloco_informacoes{
					width: 100%;
					display: block;
					margin: 1rem 0 0 0;
					padding-left: 0;
					
					& .miniatura_informacoes{
						display: block;
						width: 100%;
	
						& .miniatura_titulo{
							display: block;
							width: 100%;
					
							& p{
								font-size: .9rem;
								font-weight: 500;
								color: $gray1;
								margin: 0;
							}
						}
							
						& .codigo_imovel{
							width: fit-content;
							display: block;
							margin: 0;
	
							& p{
								width: fit-content;
								display: inline-block;
								vertical-align: middle;
								font-size: .8rem;
								font-weight: 400;
								color: $gray1;
								padding-right: 1rem;
							}
																
							& .miniatura_acao{
								width: fit-content;
								display: inline-block;
								vertical-align: middle;
								font-size: .8rem;
								font-weight: 400;
								color: $gray1;
								margin: 0;
								background-color: transparent;
							}
						}
		
						& .miniatura_endereco{
							display: block;
							width: 100%;
							& p{
								font-size: .8rem;
								font-weight: 400;
								color: $gray2;
								margin: .5rem 0 0 0;
	
								& i{
									font-size: .7rem;
									font-weight: 600;
									color: $colorPrimary;
								}
							}
						}
		
						& .miniatura-imovel-btns-icons{
							display: block;
							width: 100%;
				
							& ul{
								& li{
									color: $gray2;
									font-size: .7rem;
									font-weight: 500;
	
									& span{
										font-size: 1rem;
										color: $colorPrimary;
									}
								}
	
							}
						}
					}
				}
			}
		}
	}
}