.section_corretores {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	& .titleCorretores {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		& h2 {
			font-size: 1.75rem;
			font-weight: 600;
			color: $colorTerciary;
			margin: 0;
			text-align: center;
			padding-bottom: .5rem;
		}

		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 3px;
			margin: auto;
			background-color: $colorPrimary;
			border-radius: 2rem;
		}
	}

	& .containerCorretoresIndex {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
		gap: 5rem;
		width: 100%;
		overflow: auto;
		padding: 1rem 0;
		
		& .miniatura_corretor_index {
			flex-basis: 15%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin: 0;
			gap: 1rem;
			max-width: 200px;
			
			& .miniatura_corretor_img {
				width: 200px;
				height: 200px;
				border-radius: 1rem;
				position: relative;
				overflow: hidden;
				margin: 0;
		
				& img {
					width: 200px;
					height: 200px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					object-fit: fill;
				}
			}
		
			& .corretor_info {
				width: 100%;
				margin: 0;
				box-sizing: border-box;
		
				& h3 {
					font-size: .9rem;
					font-weight: 600;
					color: $colorTerciary;
					margin: 0;
					line-height: 1.5rem;
					text-align: left;
					word-wrap: break-word;
				}
		
				& p {
					font-size: .8rem;
					font-weight: 500;
					color: $gray2;
					margin: 0;
					line-height: 1.5rem;
					word-wrap: break-word;
				}
		
				& a {
					width: 100%;
					display: block;
		
					& p {
						transition: all .15s ease-in-out;
						word-wrap: break-word;
					}
					
					&:hover{
						& p {
							color: $colorTerciary;
						}
					}
				}
			}
		}
	}
}

.miniatura_corretor_interna {
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid 1px lighten($gray4, 15);
	border-radius: 1rem;
	padding: .5rem;

	& .miniatura_corretor_interna_img {
		width: 25%;
		overflow: hidden;
		border-radius: 50%;


		& img {
			width: 100%;
			margin: auto;
			border-radius: 50%;
		}
	}

	& .corretor_interna_info {
		width: 75%;
		padding-left: 1rem;

		& h4 {
			font-size: .9rem;
			font-weight: 500;
			color: $gray1;
			margin: 0;
			line-height: 1rem;
			text-align: left;
		}

		& p {
			font-size: .8rem;
			font-weight: 400;
			color: $gray2;
			margin: 0;
			line-height: 2rem;
			width: fit-content;
		}

		& .btn_zap_lead {
			width: fit-content;
			display: block;
			margin: 0;
			
			& p {
				background-color: darken(#25d366, 5);
				color: white;
				font-size: .9rem;
				font-weight: 600;
				padding: .3rem 1.5rem;
				border-radius: 2rem;
				line-height: inherit;
				vertical-align: middle;
				transition: background-color .2s ease-in-out;
				cursor: pointer;
				user-select: none;

				& i{
					font-size: 1.3rem;
					font-weight: 400;
					vertical-align: middle;
				}

				&:hover{
					background-color: darken(#25d366, 10);
				}
			}
		}
	}
}

.section_dialog3{
	display: block;
	width: 90%;
	border: solid 1px lighten($gray4, 10);
	box-shadow: 0 0 10px 0 rgba(black, .1);
	padding: 1rem;
	border-radius: 1rem;
	position: absolute;
	bottom: 10%;
	left: 0%;
	background-color: white;

	& .btn_fechar_form_zap{
		display: flex;
		position: absolute;
		top: -1.8rem;
		right: -1.8rem;
		background-color: white;
		border: solid 2px #00af53;
		padding: .5rem;
		border-radius: 50%;
		width: 2.1rem;
		height: 2.1rem;
		align-items: center;
		justify-content: center;
		transition: all .2s ease-in-out;
		cursor: pointer;
		& i{
			margin: 0;
			padding: 0;
			line-height: initial;
			color: $gray3;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: #00af53;
			& i{
				color: white;
			}
		}
	}

	& .dialog_wpp{
        display: block;
        width: 100%;
        padding: 0;

        & .info_corretor{
            width: 100%;
            display: block;

            & .img{
                display: inline-block;
                vertical-align: middle;
                width: 23%;

                & img{
                    width: 100%;
                    border-radius: 50%;
                }
            }

            & p{
                display: inline-block;
                vertical-align: middle;
                width: 77%;
                padding-left: 1rem;
                font-size: .9rem;
            }
        }

        & .inputs_wpp{
            display: block;
            width: 100%;

            & .inputs_dados{
                width: 50%;
                display: inline-block;
                vertical-align: top;
                margin: 0;
                padding: 0 .5rem 0 0;
                
                & p{
                    margin: 0;
                    line-height: 1.5rem;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: left;
                    color: $gray2;
                }
    
                & input{
                    border: solid 1px $gray4;
                    border-radius: 2rem;
                    width: 100%;
                    display: block;
                    padding: .7rem 1rem;
                }
    
            }

            & .input_msg{
                width: 50%;
                display: inline-block;
                vertical-align: top;
                margin: 0;
                padding: 0 0 0 .5rem;
                
                & input{
                    border: solid 1px $gray4;
                    border-radius: 2rem;
                    width: 100%;
                    display: block;
                    padding: .7rem 1rem;
                }

                & button{
                    width: 100%;
                    border: none;
                    border-radius: 2rem;
                    background-color: #25d366;
                    color: white;
                    font-size: .9rem;
                    font-weight: 600;
                    padding: .6rem 1rem;
                    cursor: pointer;

                    & i{
                        color: white;
                        font-size: 1.2rem;
                        font-weight: 400;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1366px){

	.section_corretores {
		& .containerCorretoresIndex {
			gap: 8rem;
			
			& .miniatura_corretor_index {
				& .miniatura_corretor_img {
					width: 200px;
					height: 200px;
			
					& img {
						width: 200px;
						height: 200px;
					}
				}
			
				& .corretor_info {
					width: 100%;
					margin: 0;
			
					& h3 {
						font-size: .9rem;
						line-height: 1.5rem;
					}
			
					& p {
						font-size: .75rem;
						line-height: 1.5rem;
					}
				}
			}
		}
	}

	.miniatura_corretor_footer {
		gap: 1rem;
	
		& .miniatura_corretor_footer_img {
			width: 4.5rem;
			height: 4.5rem;
		}
	}

	.miniatura_corretor_interna {
		& .corretor_interna_info {
			& .btn_zap_lead {
				& p {
					font-size: .8rem;
	
					& i{
						font-size: 1.2rem;
					}
				}
			}
		}
	}
	
}

@media (max-width: 1023px){

	.section_corretores {
		& .titleCorretores {
			& h2 {
				font-size: 1.3rem;
			}
	
			& .under_p{
				height: 3px;
			}
		}
	
		& .containerCorretoresIndex {
			flex-direction: column;
			gap: 5rem;
			
			& .miniatura_corretor_index {
				width: fit-content;
				margin: auto;

				& .miniatura_corretor_img {
					width: 225px;
					height: 225px;
			
					& img {
						width: 225px;
						height: 225px;
					}
				}
			
				& .corretor_info {
					& h3 {
						font-size: .9rem;
						line-height: 1.5rem;
					}
			
					& p {
						font-size: .8rem;
						line-height: 1.75rem;
					}
			
					& a {
			
						& p {
							word-wrap: break-word;
						}
					}
				}
			}
		}
	}

	.miniatura_corretor_footer {
		gap: 1rem;
	
		& .miniatura_corretor_footer_img {
			width: 5.5rem;
			height: 5.5rem;
		}
	
		& .corretor_footer_info {
			& p {
				font-size: .8rem;
				line-height: 1.5rem;
			}
		}
	}

	.miniatura_corretor_interna {
		padding: .5rem 0;
	}

	.section_dialog3{
		display: block;
		width: 90vw;
		margin: auto;
	}
}