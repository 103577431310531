.section_indices {
	background-color: $gray5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& .titleIndices {
		text-align: center;
		display: block;
		width: 100%;
		
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
	
			& span{
				font-size: 1.3rem;
				font-weight: 400;
				color: $gray2;
			}
		}
	
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 3px;
			margin: auto;
			background-color: $colorPrimary;
			border-radius: 2rem;
		}
	}

	& .containerIndices {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 2rem;
		width: 100%;

		& .item_indices {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: .75rem;
			margin: 0;
			border: solid 1px lighten($gray4, 10);
			border-radius: .5rem;
			padding: 1rem;
			background-color: white;

			& .ind_nome_valor {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				margin: 0;
				
				& .ind_nome {
					color: $gray2;
					font-size: .9rem;
					font-weight: 600;
					text-align: right;
					margin: 0;
				}

				& .ind_valor {
					color: $gray1;
					font-size: .9rem;
					font-weight: 600;
					text-align: left;
					margin: 0;
				}
			}
			
			& .valor_anual {
				color: $gray2;
				font-size: .75rem;
				font-weight: 500;
				margin: 0;
			}

			& .ind_data {
				color: $gray2;
				font-size: .75rem;
				font-weight: 500;
				margin: 0;
			}
		}
	}
}

@media (max-width: 1023px) {

	.section_indices {
		& .titleIndices {
			& h2{
				font-size: 1.3rem;
			}
		}
	
		& .containerIndices {
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
		}
	}
}