.header1{
	background-color: white;
	width: 100%;
	box-shadow: 0 3px 10px 1px rgba($gray2, .1);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: .25rem;
	position: relative;

	& .superior {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		width: 100%;

		& .logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: calc(200px / 2);
			height: calc(200px / 2);
			margin: 0;

			& img {
				display: block;
				width: calc(200px / 2);
				height: calc(200px / 2);
				margin: 0;
			}
		}

		& .sessions {
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin: 0;
			width: 100%;

			& a {
				& p {
					font-size: .9rem;
					font-weight: 500;
					color: $gray2;
					margin: 0;

					& i {
						color: $colorPrimary;
						vertical-align: middle;
						font-size: 1rem;
					}
				}
			}

			& .centralHeader {
				margin: 0;

				& a {
					& p {
						background-color: $colorPrimary;
						margin: 0;
						padding: .5rem 1rem;
						border-radius: 2rem;
						color: $colorTerciary;
						font-size: .9rem;
						font-weight: 600;
						transition: all .15s ease-in-out;

						& i {
							color: $colorTerciary;
							font-size: .9rem;
						}

						&:hover {
							background-color: darken($colorPrimary, 5);
						}
					}
				}
			}
		}
		
		& .menu {
			margin: 0;
			display: block;
			width: fit-content;

			& button {
				border: solid 1px lighten($gray4, 10);
				background-color: white;
				font-size: .9rem;
				font-weight: 600;
				color: $gray2;
				padding: .5rem 1rem;
				border-radius: 2rem;
				cursor: pointer;
				transition: border-color .1s ease-out;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				& i {
					color: $colorPrimary;
				}

				&:hover {
					border-color: $colorPrimary;
				}
			}
		}
	}
}

.headerindex {
	height: calc(100vh - 6.7rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		left: 0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, rgba(black, .3), rgba(black, .3));
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;//era block
			height: 100%;
			width: 100%;
			z-index: 2;
			background-image: url('/jnh/header/_bg_header2.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-color: lighten($colorPrimary, 15);
			filter: grayscale(85%);
		}
	}
}


/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media (min-width: 1024px) and (max-width: 1366px){

	.headerindex {
		height: calc(100vh - 6.9rem);
		& .container-bg-headerpc{
	
			& .overlay_bg_home{
				background: linear-gradient(to bottom, rgba(black, .3), rgba(black, .3));
			}
	
			& .bg-headerpc{
				background-position: center top 50%;
			}
		}
	}
}

@media (max-width: 1023px){
	
	.headerindex {
		height: fit-content;
		padding: 2rem;
		box-sizing: border-box;
		
		& .container-bg-headerpc{
			& .overlay_bg_home{
				background: linear-gradient(to bottom, rgba(black, .25), rgba(black, .25));
			}
	
			& .bg-headerpc{
				background-position: center left 46%;
			}
		}
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}

	.header_mob{
		background-color: white;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: .5rem 1rem;
		box-shadow: 0 0 10px 3px rgba($gray2, .15);
		position: relative;

		& .header-mob{
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
		
			& .log{
				display: block;
				width: calc(200px / 3);
				height: calc(200px / 3);
				margin: 0;

				& img{
					width: calc(200px / 3);
					height: calc(200px / 3);
				}
			}
	
			& .btn-men{
				display: block;
				width: fit-content;
				margin: 0;

				& button{
					color: $colorTerciary;
					background-color: transparent;
					border: solid 1px lighten($gray4, 10);
					border-radius: 2rem;
					padding: .5rem 1rem;
					cursor: pointer;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					font-size: 1rem;
					font-weight: 500;
					transition: all .15s ease-in-out;

					& i{
						color: $colorPrimary;
						font-size: 1.1rem;
						font-weight: 600;
					}

					&:hover {
						border-color: $colorPrimary;
					}
				}
			}
		}
	}
}
