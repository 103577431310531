.busca {
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 100%;
	z-index: 2;

	& .conteudoBuscaIndex {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		gap: 2rem;

		& .slogan{
			display: block;
			width: 100%;
			margin: 0;
	
			& h1{
				font-size: 1.4rem;
				font-weight: 600;
				color: white;
				text-shadow: 0 0 5px $gray1;
				text-align: center;
				line-height: 2rem;
			}
		
			& h2{
				font-size: 1.1rem;
				font-weight: 500;
				color: white;
				text-shadow: 1px 1px 3px $gray1;
				text-align: center;
			}
		}

		& .btnsBuscaHome {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 70%;
			border-radius: 2rem;
			gap: 1rem;

			& a {
				display: block;
				width: 100%;
				
				& p {
					margin: 0;
					background-color: $colorPrimary;
					color: $colorTerciary;
					text-align: center;
					padding: 1.5rem 0;
					font-size: 1rem;
					font-weight: 600;
					text-transform: uppercase;
					transition: all .15s ease-in-out;
					
					
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
				}
			}

			& .area1 {
				width: 100%;
				
				& a{
					& p {
						border-radius: .5rem;
						border: solid 2px white;
					}
				}
			}
	
			& .area2 {
				display: none;
				background-color: rgba($colorTerciary, .7);
				width: 5rem;
				height: 100%;
				border: solid 2px white;
				border-left: none;
				border-right: none;
			}

			& .area3 {
				width: 100%;
				
				& a{
					& p {
						border-radius: .5rem;
						border: solid 2px white;
					}
				}
			}
			
			& .area4 {
				display: none;
				background-color: rgba($colorTerciary, .7);
				width: 5rem;
				height: 100%;
				border: solid 2px white;
				border-left: none;
				border-right: none;
			}
			
			& .area5 {
				width: 100%;
				
				& a{
					& p {
						border-radius: .5rem;
						border: solid 2px white;
					}
				}
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .5rem 0;
	border: none;
	border-radius: 2rem;
	background-color: $colorPrimary;
	color: white;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .5rem 0;
	border: solid 1px lighten($gray4, 10);
	border-radius: 2rem;
	background-color: white;
	color: $gray2;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: border-color .2s ease-in-out;

	&:hover{
		border-color: lighten($colorPrimary, 10);
	}
}

.btns_busca_filtros_mob {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: .5rem;
}

.filtros_avancados_e_ordenar {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	& .filtros_ord {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;
		margin: 0;
	}
}

.container_infras_buscaInterna {
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	border-radius: .5rem;
	
	& .titleInfraGroup {
		font-size: .9rem;
		font-weight: 400;
		color: $gray1;
		padding: .7rem 1rem;
		margin: 0;
		position: relative;
		cursor: pointer;
		transition: all .2s ease-in-out;
		user-select: none;
		border-radius: .5rem;

		& i{
			position: absolute;
			right: 1.5rem;
			color: $colorPrimary;
			top: 40%;
			transition: all .2s ease-in-out;
		}
	}

	& .infraGroups{
		padding: .5rem;

		& label{
			font-size: .9rem !important;
			& span{
				width: 13px !important;
				height: 13px !important;
				float: none !important;
				border-radius: 50% !important;
			}
		}
	}

	&:hover{
		border-color: lighten($colorPrimary, 10);
		
		& .titleInfraGroup {
			background-color: lighten($gray5, 2);
		}
	}
}

.btns_ver_resultados_infras {
	display: block;
	width: 100%;
	padding-top: 1rem;

	& .btn_pc_Infras {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
}

@media (min-width: 1024px) and (max-width: 1366px){

	.busca {
		width: 80%;
	
		& .conteudoBuscaIndex {
			gap: 1rem;
	
			& .slogan{
				& h1{
					font-size: 1.2rem;
					text-shadow: 1px 1px 2px $gray1;
					line-height: 1.5rem;
				}
			
				& h2{
					text-shadow: 1px 1px 2px $gray1;
				}
			}
	
			& .btnsBuscaHome {
				width: 70%;
	
				& a {
					& p {
						padding: 1.3rem 0;
						font-size: .9rem;
					}
				}
			}
		}
	}

	.botao_buscar_int{
		padding: .6rem 0;
	}
	
	.botao_limpar_int{
		padding: .6rem 0;
		color: $gray2;
	}
}

@media (max-width: 1023px){

	.busca {
		width: 100%;
	
		& .conteudoBuscaIndex {
			gap: 1rem;
	
			& .slogan{
				& h1{
					font-size: 1.1rem;
					text-shadow: 1px 1px 2px $gray1;
					line-height: 1.5rem;
				}
			
				& h2{
					font-size: 1rem;
					text-shadow: 1px 1px 2px $gray1;
					line-height: 1.2rem;
				}
			}
	
			& .btnsBuscaHome {
				flex-direction: column;
				width: 80%;
				border-radius: 0;
				background-color: transparent;
				border: none;
				gap: 1rem;
				overflow: auto;
	
				& .area1 {
					width: 100%;
					
					& a{
						& p {
							border-radius: .5rem;
							border: solid 2px white;
						}
					}
				}
		
				& .area2 {
					display: none;
				}
	
				& .area3 {
					& a{
						& p {
							border-radius: .5rem;
							border: solid 2px white;
						}
					}
				}
				
				& .area4 {
					display: none;
				}
				
				& .area5 {
					width: 100%;
					
					& a{
						& p {
							border-radius: .5rem;
							border: solid 2px white;
						}
					}
				}
			}
		}
	}

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.filtros_avancados_e_ordenar {
		flex-direction: column;
		gap: 2rem;
		
		& .filtros_ord {
			width: 100%;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
			gap: 0;
			margin: 0;
		}
	}
}

